import React from "react"
// import "../../styles/style.css"
import Header from "../../components/Header/"
import Footer from "../../components/Footer/"

import {
  Titulo,
  ContatoContainer,
  FormContainer,
  Form,
} from "../../styles/styles"

export default function Contato() {
  return (
    <div>
      <Header />
      <Titulo>
        <h2>Contato</h2>
        <hr />
      </Titulo>
      <ContatoContainer>
        <img
          src="https://mauricio-lambiasi.s3-sa-east-1.amazonaws.com/mauricioContato.jpeg"
          alt="mauricio"
        />
        <FormContainer>
          <Form>
            <h1>Entre em contato</h1>
            <br/>
            <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=mlambiasi@mauriciolambiasi.com" target="_blank">mlambiasi@mauriciolambiasi.com</a>
            <br/>
            <a href="//api.whatsapp.com/send?phone=5511999998505&text=Olá Mauricio" target="_blank">+55 11 99999-8505</a>
            <br/>
            <a href="https://www.linkedin.com/in/mauricio-lambiasi/" target="_blank">Linkedin</a>
          </Form>
        </FormContainer>
      </ContatoContainer>
      <Footer />
    </div>
  )
}
